/* ----------------------------------------------------------------
	Canvas: Non-Profit
-----------------------------------------------------------------*/

/* Root Settings
-----------------------------------------------------------------*/
:root {
  --cnvs-themecolor: #c6c09c;
  --cnvs-themecolor-rgb: 198, 192, 156;

  --cnvs-body-font: "Poppins", sans-serif;
  --cnvs-secondary-font: "Caveat Brush", cursive;
}

#header {
  --cnvs-header-height: 70px;
  --cnvs-header-bg: var(--cnvs-themecolor);
  --cnvs-header-sticky-bg: var(--cnvs-header-bg);
}

.heading-block h2,
.heading-block h3 {
  font-family: var(--cnvs-secondary-font) !important;
}

.bg-color-light {
  background-color: rgba(
    var(--cnvs-themecolor-rgb, 198, 192, 156),
    0.3
  ) !important;
  border-color: rgba(var(--cnvs-themecolor-rgb, 198, 192, 156), 0.4);
}

/* Slider
-----------------------------------------------------------------*/
.swiper-container-horizontal > .swiper-scrollbar {
  top: auto;
  bottom: 180px;
  left: auto;
  right: 100px;
  width: 200px;
  height: 2px;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 2px;
}

.swiper-scrollbar-drag {
  background: #000;
}

.slide-number {
  opacity: 1;
  bottom: 10px;
  text-align: left;
  right: auto;
}

.slide-number-current {
  top: auto;
  bottom: 0;
  font-size: 30px;
  font-weight: 700;
}

.slide-number span {
  margin-left: 20px;
  font-size: 16px;
}

.slide-number-total {
  font-size: 13px;
  line-height: 28px;
  left: 29px;
}

.swiper-navs {
  position: absolute;
  left: auto;
  right: 300px;
  bottom: 140px;
  top: auto;
  z-index: 99;
}

.slider-arrow-left,
.slider-arrow-right {
  border-radius: 50%;
  background: transparent;
  border: 1px solid rgba(0, 0, 0, 0.7);
  width: 36px;
  height: 36px;
}

.slider-arrow-right {
  left: 45px;
}

.slider-arrow-left i,
.slider-arrow-right i {
  display: block;
  width: 36px;
  height: 36px;
  font-size: 16px;
  line-height: 34px;
  margin: 0 auto;
  color: #000;
}

.slider-arrow-right i {
  margin-left: -1px;
}

.swiper-button-disabled {
  opacity: 0.5;
  cursor: default;
}

.dark .slider-arrow-left,
.dark .slider-arrow-right {
  border-color: rgba(255, 255, 255, 0.7);
}

.dark .swiper-container-horizontal > .swiper-scrollbar {
  background-color: rgba(255, 255, 255, 0.4);
}

.dark .swiper-scrollbar-drag {
  background: #fff;
}

.dark .slide-number,
.dark .slider-arrow-left i,
.dark .slider-arrow-right i {
  color: #fff;
}

.slider-feature {
  position: relative;
  transform: translateY(-20%);
  z-index: 4;
}

.slider-feature a {
  color: #222;
  transition: all 0.2s ease;
}

.slider-feature a:hover {
  background-color: var(--cnvs-themecolor);
  transform: translateY(-2px);
  z-index: 1;
}

.slider-feature a i {
  position: relative;
  top: 4px;
  font-size: 20px;
  padding-right: 6px;
}

/* SVG Divider
-----------------------------------------------------------------*/
.svg-line {
  display: block;
  position: relative;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 100% 100%;
  width: 100%;
  height: 20px;
}

.button-svg {
  /* background-image: url("/border.svg"); */
  background-image: url("../nonprofit/images/border.svg");
  background-repeat: no-repeat;
  background-position: center 120%;
  background-size: 100% 10px;
  color: #222;
  font-size: 16px;
}

/* Animated Setting
-----------------------------------------------------------------*/
.animated {
  -webkit-animation-duration: 0.4s;
  animation-duration: 0.4s;
}

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 30px, 0);
    transform: translate3d(0, 30px, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 30px, 0);
    transform: translate3d(0, 30px, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

/* Carousel
-----------------------------------------------------------------*/
.oc-desc {
  position: absolute;
  top: 5%;
  left: 5%;
  bottom: 16px;
  background-color: #fff;
  padding: 25px;
  border-radius: 3px;
  height: 90%;
  width: 90%;
}
.charity-card p {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}

.charity-card h3 {
  font-size: 16px;
}
.charity-card h3 a {
  color: #111;
}

.charity-card .skills li .progress::after {
  content: "\e9e3";
  font-family: "font-icons";
  color: var(--cnvs-themecolor);
  position: absolute;
  right: -8px;
  top: 50%;
  transform: translateY(-50%);
}

.charity-card .counter.counter-inherit.counter-instant {
  display: inline-block;
}

.charity-card .skills li .progress-percent {
  display: inherit;
  position: relative;
  background-color: transparent;
  color: #222;
  text-shadow: none;
  font-size: 14px;
  font-weight: 400;
  top: -30px;
  width: 100%;
  left: 0;
  height: auto;
  line-height: inherit;
  padding: 0;
  border-radius: 0;
}

.skills li .progress-percent {
  right: -14px;
}

.progress:not(.skills-animated) {
  opacity: 0;
}

.charity-card .progress-percent::after,
.progress-percent::before {
  display: none;
}

.charity-card .skills li .progress-percent .counter {
  display: block;
  position: relative;
  white-space: nowrap;
}

.owl-carousel .owl-item .oc-desc {
  opacity: 0;
  transform: scale(0.7);
  transition: all 0.3s cubic-bezier(0.4, 0.2, 0.2, 1);
}

/* .owl-carousel .owl-item:hover .oc-desc, */
.owl-carousel .owl-item.active.center .oc-desc {
  opacity: 1;
  transform: scale(1);
}

.world-map-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  z-index: 1;
}

.counter-section [class^="col-"]:not(:last-child)::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 10px;
  height: 100%;
  opacity: 0.4;
  background-image: url("../nonprofit/images/divider-2.svg");
  background-repeat: no-repeat;
  background-position: center center;
}

.device-sm .counter-section [class^="col-"]:nth-child(2)::after,
.device-md .counter-section [class^="col-"]:nth-child(2)::after,
.device-xs .counter-section [class^="col-"]::after {
  display: none;
}

/* Play Icon
-----------------------------------------------------------------*/
.play-video i {
  display: block;
  width: 60px;
  height: 60px;
  line-height: 61px;
  border-radius: 50%;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.85);
  text-align: center;
  font-size: 24px;
  color: #111;
  transition: all 0.3s ease;
  box-shadow: 0 0 1px 15px rgba(255, 255, 255, 0.1);
  -webkit-backface-visibility: hidden;
}

.play-video i.icon-small {
  width: 40px;
  height: 40px;
  line-height: 41px;
  font-size: 16px;
  box-shadow: 0 0 1px 10px rgba(255, 255, 255, 0.1);
}

.play-video:hover i {
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2);
  background-color: #fff;
}

.bg-color-shadow::before {
  content: "";
  position: absolute;
  top: -15px;
  left: -15px;
  width: 100%;
  height: 100%;
  z-index: -1;
  border-radius: 6px;
  opacity: 0.3;
  background-color: var(--cnvs-themecolor);
}

.bg-color-shadow.left::before {
  top: -10px;
  left: -10px;
}

/* Heading Block
-----------------------------------------------------------------*/
.heading-block h2 {
  font-size: 36px;
  letter-spacing: -1px;
}

/* Missions Gloals
-----------------------------------------------------------------*/
.mission-goals .feature-box {
  box-shadow: 0 0 10px rgba(99, 114, 130, 0.15);
  padding: 25px 20px;
  border-radius: 10px;
}

/* Team/Volunteers
-----------------------------------------------------------------*/
.team-title {
  font-size: 17px;
}

.team-title small {
  display: block;
  font-size: 13px;
  color: #aaa;
}

.team-image img {
  border-radius: 6px;
}

.team.full-border {
  border: 5px solid var(--cnvs-themecolor);
  border-radius: 4px;
}

/* Subscribe Section
-----------------------------------------------------------------*/
.subscribe-section .subscribe-section-target {
  cursor: pointer;
  padding: 35px 0;
}

.subscribe-icon {
  position: absolute;
  top: 0px;
  left: 50%;
  width: 120px;
  height: 120px;
  line-height: 110px;
  background-color: var(--cnvs-themecolor);
  border-radius: 50%;
  text-align: center;
  transform: translate(-50%, -45%);
}

.subscribe-icon i {
  font-size: 38px;
  text-align: center;
  color: #444;
  font-weight: 600;
}

.subscribe-button {
  opacity: 0;
  display: block;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 50%) scale(0.5);
  transition: opacity 0.3s linear;
  z-index: 1001;
}

body:not(.nonprofit-success):not(.nonprofit-processing)
  .show
  .subscribe-button {
  opacity: 1;
  transform: translate(-50%, 50%) scale(1);
}

.nonprofit-loader.css3-spinner {
  background-color: rgba(var(--cnvs-themecolor-rgb, 198, 192, 156), 0.5);
}

.css3-spinner > div {
  background-color: #111;
}

#nonprofit-submitted,
.nonprofit-success #nonprofit,
body:not(.nonprofit-processing) .nonprofit-loader {
  display: none;
}

.subscribe-section-target .fa-arrow-down {
  transform: rotate(0deg);
  transition: transform 0.2s linear;
}

.subscribe-section-target:not(.collapsed) .fa-arrow-down {
  transform: rotate(180deg);
}

.subscribe-section .subscribe-icon i {
  transform: scale(1);
  transition: transform 0.25s ease;
}
.subscribe-section:hover .subscribe-icon i {
  transform: scale(1.2);
}

.nonprofit-success #nonprofit-submitted {
  display: block;
}

/* Border Form Design
---------------------------------------------------------------------------- */
.form-control.border-form-control {
  --cnvs-input-btn-padding-y: 8px;
  --cnvs-input-btn-padding-x: 4px;
  --cnvs-input-btn-font-size: 1.25rem;
  --cnvs-input-btn-border-width: 0px;
  background-image: url("../nonprofit/images/divider-3.svg");
  background-repeat: no-repeat;
  background-position: bottom center;
}

.form-control.border-form-control::-moz-placeholder {
  opacity: 0.4;
}
.form-control.border-form-control:-ms-input-placeholder {
  opacity: 0.4;
}
.form-control.border-form-control::-webkit-input-placeholder {
  opacity: 0.4;
}

/* Event Calendar
-----------------------------------------------------------------*/
.fc-calendar {
  background: #fff;
  box-shadow: 0 0 10px rgba(99, 114, 130, 0.15);
}

.fc-calendar .fc-row > div.fc-today,
.fc-calendar .fc-row > div > div a,
.fc-calendar .fc-row > div > div span {
  background-color: var(--cnvs-themecolor);
  box-shadow: none;
}

.fc-calendar .fc-row > div.fc-today > span.fc-date,
.fc-calendar .fc-row > div > div a:not(:hover),
.fc-calendar .fc-row > div > div span:not(:hover) {
  color: #222;
  text-shadow: none;
}

.fc-content {
  overflow-y: scroll !important;
}

.fc-calendar-event + .fc-calendar-event {
  border-top: 1px solid rgba(0, 0, 0, 0.3);
  padding-top: 12px;
  margin-top: 8px;
}

.fc-allday[datetime="true"] + .fc-starttime,
.fc-allday[datetime="true"] + .fc-starttime + .fc-endtime {
  display: none;
}

.fc-emptydate,
.fc-weekday {
  opacity: 0.07 !important;
}

.fc-past.fc-content .fc-calendar-event a {
  background-color: rgba(var(--cnvs-themecolor-rgb, 198, 192, 156), 0.5);
  pointer-events: none;
  color: #666 !important;
}

.fc-body {
  background: url("../nonprofit/images/world-map.png") no-repeat center center/
    contain;
}

/* Section Details
-----------------------------------------------------------------*/
.section-details p {
  color: #999;
}

.testi-content p {
  font-style: normal;
  padding-left: 30px;
  border-left: 3px solid rgba(var(--cnvs-themecolor-rgb, 198, 192, 156), 0.2);
}

.testimonials-carousel .owl-stage {
  padding: 5px 0;
}

.testimonials-carousel::before {
  content: '"';
  position: absolute;
  top: -20px;
  left: 22px;
  font-size: 60px;
  color: rgba(var(--cnvs-themecolor-rgb, 198, 192, 156), 0.4);
  z-index: -1;
  font-family: "Segoe Print", "Times New Roman", Verdana;
}

.testi-meta {
  margin: 10px 0 0 30px;
}

/* Floating Contact
-----------------------------------------------------------------*/
.floating-contact-wrap {
  position: fixed;
  right: 50px;
  bottom: 120px;
  z-index: 299;
}

.floating-contact-wrap .floating-contact-btn {
  position: absolute;
  left: 0;
  top: 0;
  width: 50px;
  height: 50px;
  border-radius: 8px;
  background-color: var(--cnvs-themecolor);
  color: #fff;
  cursor: pointer;
  z-index: 3;
  color: #222;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  transition: transform 0.3s ease;
}

.floating-contact-wrap .floating-contact-btn:hover,
.floating-contact-wrap.active .floating-contact-btn {
  -webkit-transform: translate(-50%, -50%) scale(1.1);
  transform: translate(-50%, -50%) scale(1.1);
  background-color: #222;
  color: #fff;
}

.floating-contact-wrap .floating-contact-btn .floating-contact-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 22px;
  -webkit-transition: 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: opacity, transform;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-backface-visibility: hidden;
}

.floating-contact-wrap .floating-contact-btn .floating-contact-icon.btn-active,
.floating-contact-wrap.active .floating-contact-btn .floating-contact-icon {
  opacity: 0;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.floating-contact-wrap.active
  .floating-contact-btn
  .floating-contact-icon.btn-active {
  opacity: 1;
  -webkit-transform: translate(-50%, -50%) rotate(-45deg);
  transform: translate(-50%, -50%) rotate(-45deg);
}

.floating-contact-wrap .floating-contact-box {
  opacity: 0;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 380px;
  background-color: #fff;
  border-radius: 6px;
  z-index: 1;
  -webkit-transform-origin: right bottom;
  transform-origin: right bottom;
  box-shadow: 0px 0px 13px 3px rgba(0, 0, 0, 0.07);
  -webkit-transform: scale(0.01);
  transform: scale(0.01);
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-backface-visibility: hidden;
}

.floating-contact-wrap.active .floating-contact-box {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.floating-contact-wrap .css3-spinner {
  background-color: rgba(255, 255, 255, 0.8);
}

.floating-contact-wrap form {
  padding: 35px 30px;
}

#floating-contact-submitted,
.floating-contact-success #floating-contact,
.floating-contact-success .floating-contact-heading,
body:not(.floating-contact-processing) .floating-contact-loader {
  display: none;
}

.floating-contact-success #floating-contact-submitted {
  display: block;
}

.full-width-img {
  margin-left: calc(50% - 540px);
  max-width: 950px;
  width: 950px;
}

@media (min-width: 768px) {
  .full-width-img {
    margin-left: calc(50% - 200px);
    max-width: 800px;
    width: 800px;
  }
}

/* Responsive Device more than 992px (.device-md >)
-----------------------------------------------------------------*/
@media (min-width: 992px) {
  .menu-container > .menu-item > .menu-link {
    font-weight: 500 !important;
    text-transform: none;
    letter-spacing: 0;
    font-size: 15px;
    transition: color 0.3s ease;
    color: #111 !important;
  }

  .sub-menu-container .menu-item:hover > .menu-link {
    background-color: transparent;
  }

  .menu-container:hover > .menu-item:not(:hover):not(.current) > .menu-link {
    color: #ddd !important;
  }

  .menu-container > .menu-item > .menu-link div::after {
    opacity: 0;
    content: "";
    position: absolute;
    background-image: url("../css/images/border.svg");
    background-repeat: no-repeat;
    background-position: center center;
    top: 50%;
    left: 50%;
    margin-top: 14px;
    width: 60%;
    height: 10px;
    background-size: 100% 10px;
    transform: translate(-50%, -50%);
    -webkit-transition: opacity 0.2s linear;
    -o-transition: opacity 0.2s linear;
    transition: opacity 0.2s linear;
  }

  .menu-container > .menu-item.current > .menu-link div::after,
  .menu-container > .menu-item:hover > .menu-link div::after {
    opacity: 1;
    width: 90%;
  }

  #header .menu-bg {
    width: 87px;
    height: var(--cnvs-header-height, 70px);
    background: #fff;
  }

  #header .menu-bg::after {
    content: "";
    position: absolute;
    left: 100%;
    top: 0;
    bottom: 0;
    background-color: #fff;
    width: 100vw;
    z-index: -1;
  }

  #header .menu-bg::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    border-left: 0px solid transparent;
    border-right-width: var(--cnvs-header-height, 70px);
    border-right-style: solid;
    border-right-color: transparent;
    border-bottom-width: var(--cnvs-header-height, 70px);
    border-bottom-style: solid;
    border-bottom-color: var(--cnvs-themecolor);
    z-index: 0;
  }

  .slider-feature {
    transform: translateY(-50%);
  }

  .heading-block h2 {
    font-size: 54px;
  }

  .feature-box .fbox-icon {
    width: 80px;
    height: 80px;
  }

  .feature-box h3 {
    font-size: 20px;
  }

  .charity-card h3 {
    font-size: 22px;
  }

  .counter {
    font-size: 48px;
    letter-spacing: 1px;
  }

  .counter-section h3 {
    font-size: 2.6rem;
  }

  .full-width-img {
    margin-left: calc(50% - 300px);
  }

  .page-title.page-title-center .breadcrumb {
    margin-top: 35px !important;
  }
}

@media (max-width: 767.98px) {
  .swiper-container-horizontal > .swiper-scrollbar {
    bottom: 140px;
    right: 30px;
    width: 140px;
  }

  .swiper-navs {
    bottom: 100px;
    right: 168px;
  }

  .floating-contact-wrap .floating-contact-box {
    width: 330px;
  }
}

.charity-card-title {
  position: relative;
  background-color: #dee4e4;
}

.charity-card-title::after {
  content: "";
  position: absolute;
  bottom: -11px;
  left: 0;
  background-image: url("../nonprofit/images/divider-5.svg");
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 100% 20px;
  width: 100%;
  height: 20px;
}
