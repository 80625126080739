.slick-next:before,
.slick-prev:before {
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: #000 !important;
}

.slick-slider {
  box-sizing: border-box;
  /* -webkit-user-select: none; */
  /* -moz-user-select: none; */
  /* -ms-user-select: none; */
  user-select: none;
  /* -webkit-touch-callout: none; */
  /* -khtml-user-select: none; */
  /* -ms-touch-action: pan-y; */
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
  width: 95% !important;
}

.owl-next {
  right: 0px !important;
  font-size: 2rem !important;
}
.owl-prev {
  left: 0px !important;
  font-size: 2rem !important;
}

.overmissoun {
  background: url("./nonprofit/images/world-map.png") no-repeat center
    center/contain;
}

@media (max-width: 992px) {
  .slick-slider {
    box-sizing: border-box;
    /* -webkit-user-select: none; */
    /* -moz-user-select: none; */
    /* -ms-user-select: none; */
    user-select: none;
    /* -webkit-touch-callout: none; */
    /* -khtml-user-select: none; */
    /* -ms-touch-action: pan-y; */
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
    width: 95% !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .swiper_wrapper {
    padding-top: 4rem;
  }
  .nav-menu {
    box-sizing: border-box;
    display: none;
  }
  .is-expanded-menu .primary-menu-trigger,
  .is-expanded-menu #page-menu-trigger {
    --cnvs-menu-trigger-gap: 0.75rem;
    display: flex;
    opacity: 1;
    pointer-events: auto;
    z-index: 1;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    padding: var(--cnvs-menu-trigger-gap);
    transition: opacity 0.3s ease;
  }

  .nav-display-render-close {
    flex-direction: column;
    display: none;
  }
  .nav-display-render-open {
    flex-direction: column;
    display: block;
  }
}

.nav-menu {
  box-sizing: border-box;
}

/* Side menu */

.main-sidemenu-div {
  position: fixed;
  top: 50%;
  right: 0;
  z-index: 3;
  transform: translateY(-50%);
}

.main-sidemenu-div > ul {
  list-style-type: none;
}
.main-sidemenu-div > ul > li {
  text-align: right;
  margin-bottom: 10px;
}
.main-sidemenu-div > ul > li > a > img {
  margin-right: -65%;
  transition: 0.5s;
  width: 80%;
}
.main-sidemenu-div > ul > li > a > img:hover {
  margin-right: 0%;
  transition: 0.5s;
  width: 80%;
}

/* more images section css */

.image-div {
  padding-bottom: 30px;
  cursor: pointer;
  padding-top: 20px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.image-div:hover {
  padding-bottom: 30px;
  cursor: pointer;
  padding-top: 20px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  transform: scale(1.05);
  transition: 0.3s;
}

/* Daily Darshan */

.daily-image {
  cursor: pointer;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  padding: 0;
}
.daily-image:hover {
  cursor: pointer;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  transform: scale(1.05);
  transition: 0.3s;
}

/* .slick-list {
  height: 700px;
} */

#header .menu-bg::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  border-left: 0px solid transparent;
  border-right-width: var(--cnvs-header-height, 70px);
  border-right-style: solid;
  border-right-color: transparent;
  border-bottom-width: var(--cnvs-header-height, 70px);
  border-bottom-style: solid;
  border-bottom-color: #c18c14;
  z-index: 0;
}

#header {
  --cnvs-header-height: 70px;
  --cnvs-header-bg: #c18c14;
  --cnvs-header-sticky-bg: #c18c14;
}

.btn-hover-effect:hover {
  background-color: #ffe5e5 !important;
  /* color: #fff; */
  border-color: #ffc0c0 !important;
}

.leftcurtain {
  width: 50%;
  height: 100%;
  top: 0px;
  left: 0px;
  position: absolute;
  z-index: 9999;
}
.leftcurtain-open {
  width: 0%;
  height: 100%;
  top: 0px;
  left: 0px;
  position: absolute;
  z-index: 9999;
  transition: 5s;
}

.left-img {
  width: 100%;
  height: 100%;
}
.right-img {
  width: 100%;
  height: 100%;
}

.rightcurtain {
  width: 51%;
  height: 100%;
  right: 0px;
  top: 0px;
  position: absolute;
  z-index: 9999;
}
.rightcurtain-open {
  width: 0%;
  height: 100%;
  right: 0px;
  top: 0px;
  position: absolute;
  z-index: 9999;
  transition: 5s;
}

.rope {
  position: absolute;
  top: -40px;
  left: 81%;
  z-index: 999999999;
}
.rope-open {
  position: absolute;
  top: -400px;
  left: 81%;
  z-index: 999999999;
}

.hoverEffect:hover {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.success-btn-hover-effect:hover {
  color: #22c55eed !important;
  background-color: #caf1d8 !important;
}

.name {
  margin: 0.35rem 0px 0px;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  display: block;
  text-align: center;
  position: absolute;
  bottom: 0;
  right: 0;
  background: black;
  color: white;
  font-family: sans-serif;
  opacity: 12;
  visibility: hidden;
  -webkit-transition: visibility 0s, opacity 0.5s linear;
  transition: visibility 0s, opacity 0.5s linear;
}

.daily-image:hover span {
  visibility: visible;
  opacity: 0.7;
  padding: 10px;
  margin-bottom: 6px;
}

/* Customize the date picker popup */
::-webkit-datetime-edit-text {
  padding: 0 0.5em; /* Adjust spacing */
}

.darshan-date input {
  padding: 9px 20px;
  border: 2px solid #c6c09c;
  font-weight: 600;
}
